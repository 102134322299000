
* {
  box-sizing: border-box;
}


html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;

}

.container {
  margin: 1rem;
  height: 100vh;
}

.container h1 {
  margin: 1rem;
  text-align: center;
}
.container h2 {
  text-align: center;
}

.container heading {
  margin: 1rem;
  text-align: center;
}
.nav {
  background-color: #0a30a0;
  color: rgb(255, 255, 0);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  
  padding: 0 1rem;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 4;
  margin: 0;
  list-style: none;
  display: flex;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}
.validationflow {
  height: 100%;
  width: 100%;
}

.buttonStyling {
  padding: '10px 15px';
  background-color: '#1976D2';
  color: '#fff';              
  border: 'none';
  border-radius: '5px';
  cursor: 'pointer';
  font-size: '16px';
  margin: '10 px';
  position: 'fixed';
top: '10px';
right: '10px';  }

.buttonStyling:hover {
  background-color: #007bff;  
}
iframe#webpack-dev-server-client-overlay{display:none!important}
