
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999; /* Higher z-index than other elements */
}
.overlayVisible {
  display: block; /* Show the overlay when the modal is open */
}
.textField {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: "nowrap";
  font-size: 16px;
  outline: none;
  font-family: 'YourCustomFont', sans-serif; /* Replace 'YourCustomFont' with the actual font name */
}

.modal {
  background-color: rgba(255, 255, 255, 1); /* Fully opaque white background */
  z-index: 1000;
}

/* Other elements */
.mui-button {
  z-index: 999;
}
.modalButton {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  padding: 0px 24px;
}


/* Modal */

.modalContainer {
  max-width: 600px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  z-index: 1000;
}

img {
  width: 250px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.modalRight {
  width: 100%;
}


/* Add styles for a placeholder (optional) */
.textField::placeholder {
  color: #999;
}

/* Add styles for when the text field is focused (optional) */
.textField:focus {
  border-color: #1976D2;
  box-shadow: 0 0 5px rgba(25, 118, 210, 0.5);
}


.content {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: center;
  text-overflow: ellipsis;
  
  padding: 1rem 2rem;
}

.btnContainer {
  display: flex;
  padding: 1rem 1rem;
}
.btnContainer button {
  width: 100%;
  margin: .5rem;
  padding: 16px 0;
  /* border: none; */
  border: 1px solid #1976D2;
  /* color: #ffffff; */
}

.btnPrimary {
  background-color: #1976D2;
  color: white;
}
.btnPrimary:hover {
  background-color: #0a3f74;
  color: white;
  cursor: pointer;
}
.btnOutline {
  /* background-color: #a76a99; */
  background-color: white;
  color: #1976D2;
}

.bold {
  font-weight: 600;
}

@media screen and (max-width: 500px) {
  .modalContainer {
    flex-direction: column;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100vh;
  }
  img {
    width: 100%;
    max-height: 70vh;
    object-fit: cover;
  }

  .heading {
    margin: 1rem;
  }
  
}  
